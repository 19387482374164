import logo from './logo.svg';
import './App.css';
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react' 
import { useState } from 'react';

function App() {

  const [val,SetVal] = useState()
  const [size,SetSize] = useState(512)
  const [fileData,SetFileData] = useState()

  return (
    <div className="App">
      <header className="App-header">
        QR GEN
          <label style={{textAlign:'left', width:'800px'}}>URL</label>
          <input onInput={e=>SetVal(e.target.value)} style={{marginBottom:'100px', width:'800px', height:'80px',fontSize:'larger'}}></input>
          <label style={{textAlign:'left', width:'800px'}}>SIZE</label>
          <input defaultValue={size} onInput={e=>SetSize(e.target.value)} style={{marginBottom:'100px', width:'800px', height:'80px',fontSize:'larger'}}></input>
      </header>
        <QRCodeCanvas includeMargin={true} imageSettings={{height:'100px',width:'100px'}} id="svg" value={val} size={size} style={{width:{size},height:{size},marginBottom:'100px'}} />
    </div>
  );
}

export default App;
